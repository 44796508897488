import React from "react";
import Router from "next/router";
import axios from "axios";
import { NextPage, GetServerSideProps } from "next";

import { LoginToken, AuthenticationErrors } from "@lansw-tracker/common";

import { Paragraph } from "../components/Typography/Typography";
import PageContainer from "../components/PageContainer/PageContainer";
import CodeEntry from "../components/CodeEntry/CodeEntry";
import CookieWarning from "../components/CodeEntry/CookieWarning";
import theme from "../components/theme";
import { HeadingMainSmall } from "../components/Typography/Typography";
import ErrorPage from "../components/Error/ErrorPage";

interface IndexProps {
  urlCode?: string;
  codeGenerationResult?: number;
  authenticationError?: AuthenticationErrors | null;
  error?: { errorCode: number; errorMessage: string } | null;
}

const IndexPage: NextPage<IndexProps> = ({
  urlCode,
  codeGenerationResult,
  authenticationError,
  error,
}: IndexProps) => {
  if (error) {
    return <ErrorPage error={error} />;
  }

  if (codeGenerationResult === 200) {
    return (
      <PageContainer>
        <HeadingMainSmall $marginBottom={theme.spacingValues.m}>
          Welcome to the Legal Aid NSW Grants Tracker
        </HeadingMainSmall>
        {urlCode && (
          <CodeEntry
            queryID={urlCode}
            onValidCode={(): void => {
              Router.push("/updates");
            }}
          />
        )}
        <CookieWarning />
      </PageContainer>
    );
  }

  if (codeGenerationResult === 403) {
    if (authenticationError === AuthenticationErrors.FILE_LOCKED) {
      return (
        <PageContainer>
          <HeadingMainSmall>
            Welcome to the Legal Aid NSW Grants Tracker
          </HeadingMainSmall>
          <Paragraph>
            This file has been locked. Please email{" "}
            <a href="mailto:grantstracker@legalaid.nsw.gov.au">
              grantstracker@legalaid.nsw.gov.au
            </a>{" "}
            for further assistance.
          </Paragraph>
        </PageContainer>
      );
    }
    if (authenticationError === AuthenticationErrors.NO_CONSENT) {
      return (
        <PageContainer>
          <HeadingMainSmall>
            Welcome to the Legal Aid NSW Grants Tracker
          </HeadingMainSmall>
          <Paragraph $marginBottom={theme.spacingValues.xs}>
            You are no longer subscribed to receive or view updates about this
            application in the Legal Aid NSW Grants Tracker.
          </Paragraph>
        </PageContainer>
      );
    }
  }

  return (
    <PageContainer data-cy="welcome">
      <HeadingMainSmall>
        Welcome to the Legal Aid NSW Grants Tracker
      </HeadingMainSmall>
      <Paragraph>
        Legal Aid NSW has sent a link to your application to your mobile phone.
      </Paragraph>
      <Paragraph>Please follow the link.</Paragraph>
    </PageContainer>
  );
};

const getUrlCode = (code: string | string[]): string => {
  if (Array.isArray(code)) {
    return code[0] ? code[0] : "";
  }
  return code;
};

export const getServerSideProps: GetServerSideProps<IndexProps> = async ({
  req,
  res,
  query,
}) => {
  const { id: code } = query;
  const { token } = req.signedCookies || { token: null };

  const urlCode: string = code ? getUrlCode(code) : "";
  let codeGenerationResult = 404;
  let authenticationError = null;
  const error = { errorCode: 500, errorMessage: "Server error" };
  let isError = false;

  if (token) {
    try {
      // user is logged in
      const jwtlib = await import("jsonwebtoken");

      if (!process.env.APP_SECRET) {
        throw new Error("APP_SECRET not set");
      }
      
      const decoded = jwtlib.verify(token, process.env.APP_SECRET);
      
      // Ensure a valid token was decoded
      if (typeof decoded !== "object" || decoded === null) {
        throw new Error("Invalid token");
      }
      const decodedToken: LoginToken = decoded as LoginToken;

      if (urlCode && urlCode !== decodedToken.sub) {
        // they are trying to access a different file than they are logged in for,
        // log them out then move onto checking if they are a valid user
        const response = await axios
          .create({ withCredentials: true })
          .post(`${process.env.apiNew}/auth/logout`);
        if (response.headers["set-cookie"]) {
          res.setHeader("set-cookie", response.headers["set-cookie"]);
        }
        return {
          redirect: {
            destination: `${process.env.BASE_URL}?id=${urlCode}`,
            permanent: false,
          },
        }
      } else {
        return {
          redirect: {
            destination: `${process.env.BASE_URL}/updates`,
            permanent: false,
          },
        }
      }
    } catch {
      // nop
    }
  }
  if (urlCode) {
    // user is not logged in yet.
    // This API will check to see if the user with this urlCode can log in
    // if so it will have sent them an SMS OTP.
    // if not we need to get third-party confirmation.
    try {
      const response = await axios.post(
        `${process.env.apiNew}/auth/check-user`,
        {
          urlCode,
        }
      );
      if (response.data.redirectToConfirmation && response.data.clientName) {
        return {
          redirect: {
            destination: `${process.env.BASE_URL}/confirmation?id=${urlCode}`,
            permanent: false,
          },
        }
      } else {
        codeGenerationResult = 200;
      }
    } catch (ex: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
      if (ex.response && ex.response.data && ex.response.data.message) {
        codeGenerationResult = ex.response.status;
        authenticationError = ex.response.data.message;
      } else {
        codeGenerationResult = ex.response ? ex.response.status : 500;
        authenticationError = ex.response
          ? ex.response.statusText
          : "Server error";
        isError = true;
      }
    }
  }

  // This bit of mess is here because I haven't been able to make
  // typescript and Next happy at the same time.
  if (urlCode && !isError) {
    return {
      props: {
        urlCode,
        codeGenerationResult,
        authenticationError,
      },
    };
  }
  return {
    props: {
      codeGenerationResult,
      authenticationError,
      error: isError ? error : null,
    },
  };
};

export default IndexPage;
