import React from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import Link from 'next/link';

import PageContainer from '../PageContainer/PageContainer';
import { HeadingMain, Paragraph } from '../Typography/Typography';
import theme from '../theme';

interface ErrorProps {
  error: { errorCode: number; errorMessage: string };
}

const ErrorPage: React.FC<ErrorProps> = ({ error }) => {

  useEffect(() => {
    const doLogout = async (): Promise<void> => {
      await axios
      .create({ withCredentials: true })
      .post(`${process.env.api}/auth/logout`);
    }
    if (error.errorCode === 401 && error.errorMessage === 'Unauthorized') {
      doLogout();
    }
  }, []);

  const unauthorisedContent = <>
    <HeadingMain
      $fontSize={theme.fontSize.m}
      $marginBottom={theme.spacingValues.xs}
      $marginTop={theme.spacingValues.m}
    >
      You are not authorised to view this page.
    </HeadingMain>
    <Paragraph $marginBottom={theme.spacingValues.xxxs}>
      Your session may have expired. Try using the link in your SMS to log in again.
    </Paragraph>
  </>;

  const generalErrorContent = <div data-cy="general-error">
    <HeadingMain
      $fontSize={theme.fontSize.m}
      $marginBottom={theme.spacingValues.xs}
      $marginTop={theme.spacingValues.m}
    >
      Sorry, there&rsquo;s been an error. 
    </HeadingMain>
    <Paragraph $marginBottom={theme.spacingValues.xxxs}>
      Try using the link in your SMS to log in again, or go back to the <Link href={'/'} legacyBehavior><a><strong>home page.</strong></a></Link>
    </Paragraph>
    <Paragraph $marginBottom={theme.spacingValues.xxxs}>
    If you continue to see this error, please let us know by emailing <a href="grantstracker@legalaid.nsw.gov.au"><strong>grantstracker@legalaid.nsw.gov.au</strong></a>.
    </Paragraph>
  </div>;


  return (
    <React.Fragment>
      <PageContainer $withHeader>
        {(error.errorCode === 401 && error.errorMessage === 'Unauthorized')
          ? unauthorisedContent : generalErrorContent }
      </PageContainer>
    </React.Fragment>
  );
};

export default ErrorPage;
