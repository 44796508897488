var ConsentStatus;
(function (ConsentStatus) {
    ConsentStatus["Y"] = "Y";
    ConsentStatus["N"] = "N";
})(ConsentStatus || (ConsentStatus = {}));
// Note: not used currently but here for documentation purposes
var RequisitionTypes;
(function (RequisitionTypes) {
    RequisitionTypes["GENERAL_REQUISITION"] = "GENERAL REQUISITION";
    RequisitionTypes["REQUISITION_ISSUED"] = "REQUISITION ISSUED";
    RequisitionTypes["REQUISITION_RESPONSE"] = "REQUISITION RESPONSE";
    RequisitionTypes["INTENTION_TO_TERMINATE"] = "INTENTION TO TERMINATE";
})(RequisitionTypes || (RequisitionTypes = {}));
// Note: not used currently but here for documentation purposes
var EventTypes;
(function (EventTypes) {
    EventTypes["APP_SAVE"] = "APP_SAVE";
    EventTypes["APP_SUB"] = "APP_SUB";
    EventTypes["APP_ASSESS"] = "APP_ASSESS";
    EventTypes["RELEASE"] = "RELEASE";
    EventTypes["CORRO"] = "CORRO";
    EventTypes["RR_FIN"] = "RR_FIN";
    EventTypes["TR_RECVD"] = "TR_RECVD";
    EventTypes["TR_ASSESS"] = "TR_ASSESS";
    EventTypes["TR_FIN"] = "TR_FIN";
    EventTypes["REQ_GEN"] = "REQ_GEN";
    EventTypes["REQ_RESP"] = "REQ_RESP";
    EventTypes["RR_RECVD"] = "RR_RECVD";
    EventTypes["RR_ASSESS"] = "RR_ASSESS";
    EventTypes["APPEAL_FINALISED_APPROVED"] = "APPEAL_FINALISED_APPROVED";
})(EventTypes || (EventTypes = {}));
// Note: not used currently but here for documentation purposes
var OverallDecisionTypes;
(function (OverallDecisionTypes) {
    OverallDecisionTypes["GRANTED"] = "GRANTED";
    OverallDecisionTypes["REFUSED"] = "REFUSED";
})(OverallDecisionTypes || (OverallDecisionTypes = {}));
// Note: not used currently but here for documentation purposes
var CorrespondenceTypes;
(function (CorrespondenceTypes) {
    CorrespondenceTypes["REQUISITION_ISSUED"] = "REQUISITION ISSUED";
    CorrespondenceTypes["REQUISITION_RESPONSE"] = "REQUISITION RESPONSE";
    CorrespondenceTypes["LARC_OUTCOME_REFUSED"] = "LARC OUTCOME REFUSED";
    CorrespondenceTypes["LARC_DEFERRAL"] = "LARC DEFERRAL";
    CorrespondenceTypes["TRANSFER_REQUEST"] = "TRANSFER REQUEST";
    CorrespondenceTypes["RESPONSE_TO_NITT"] = "RESPONSE TO NITT";
})(CorrespondenceTypes || (CorrespondenceTypes = {}));
// Note: not used currently but here for documentation purposes
var LetterTypes;
(function (LetterTypes) {
    LetterTypes["LARC_OUTCOME_REFUSED"] = "LARC OUTCOME REFUSED";
    LetterTypes["NITT_AID_TO_CONTINUE_TO_CLIENT"] = "NITT AID TO CONTINUE TO CLIENT";
    LetterTypes["TERMINATION_TO_CLIENT"] = "TERMINATION TO CLIENT";
})(LetterTypes || (LetterTypes = {}));
var FilePhase;
(function (FilePhase) {
    FilePhase["TERMINATION"] = "TERMINATION";
    FilePhase["APPEAL"] = "APPEAL";
    FilePhase["TRANSFER"] = "TRANSFER";
    FilePhase["APPLICATION"] = "APPLICATION";
})(FilePhase || (FilePhase = {}));
var FilePhaseState;
(function (FilePhaseState) {
    FilePhaseState["ACTIVE"] = "ACTIVE";
    FilePhaseState["DORMANT"] = "DORMANT";
})(FilePhaseState || (FilePhaseState = {}));
var StatusStage;
(function (StatusStage) {
    StatusStage["RECEIVE"] = "receive";
    StatusStage["ASSESS"] = "assess";
    StatusStage["DECIDE"] = "decide";
})(StatusStage || (StatusStage = {}));
var ApplicationStatusCode;
(function (ApplicationStatusCode) {
    ApplicationStatusCode["SAVED"] = "SAVED";
    ApplicationStatusCode["ASSESSED"] = "ASSESSED";
    ApplicationStatusCode["REQUISITION_GENERATED"] = "REQUISITION_GENERATED";
    ApplicationStatusCode["REQUISITION_RECEIVED"] = "REQUISITION_RECEIVED";
    ApplicationStatusCode["REQUISITION_GENERATED_ADDITIONAL"] = "REQUISITION_GENERATED_ADDITIONAL";
    ApplicationStatusCode["DETERMINED_APPROVED"] = "DETERMINED_APPROVED";
    ApplicationStatusCode["DETERMINED_REFUSED"] = "DETERMINED_REFUSED";
    ApplicationStatusCode["APPEAL_REQUEST_RECEIVED"] = "APPEAL_REQUEST_RECEIVED";
    ApplicationStatusCode["APPEAL_ASSESSING"] = "APPEAL_ASSESSING";
    ApplicationStatusCode["APPEAL_REQUISITION_GENERATED"] = "APPEAL_REQUISITION_GENERATED";
    ApplicationStatusCode["APPEAL_REQUISITION_RECEIVED"] = "APPEAL_REQUISITION_RECEIVED";
    ApplicationStatusCode["APPEAL_REQUISITION_GENERATED_ADDITIONAL"] = "APPEAL_REQUISITION_GENERATED_ADDITIONAL";
    ApplicationStatusCode["LARC_REFERRAL"] = "LARC_REFERRAL";
    ApplicationStatusCode["LARC_REQUISITION_GENERATED"] = "LARC_REQUISITION_GENERATED";
    ApplicationStatusCode["APPEAL_FINALISED_REFUSED"] = "APPEAL_FINALISED_REFUSED";
    ApplicationStatusCode["APPEAL_FINALISED_APPROVED"] = "APPEAL_FINALISED_APPROVED";
    ApplicationStatusCode["TERMINATION_ISSUED"] = "TERMINATION_ISSUED";
    ApplicationStatusCode["TERMINATION_RESPONSE_RECEIVED"] = "TERMINATION_RESPONSE_RECEIVED";
    ApplicationStatusCode["TERMINATION_REQUISITION_GENERATED"] = "TERMINATION_REQUISITION_GENERATED";
    ApplicationStatusCode["TERMINATION_REQUISITION_RECEIVED"] = "TERMINATION_REQUISITION_RECEIVED";
    ApplicationStatusCode["TERMINATION_REQUISITION_GENERATED_ADDITIONAL"] = "TERMINATION_REQUISITION_GENERATED_ADDITIONAL";
    ApplicationStatusCode["TERMINATION_DECISION_APPROVED"] = "TERMINATION_DECISION_APPROVED";
    ApplicationStatusCode["TERMINATION_DECISION_REFUSED"] = "TERMINATION_DECISION_REFUSED";
    ApplicationStatusCode["TRANSFER_RECEIVED"] = "TRANSFER_RECEIVED";
    ApplicationStatusCode["TRANSFER_ASSESSED"] = "TRANSFER_ASSESSED";
    ApplicationStatusCode["TRANSFER_REQUISITION_GENERATED"] = "TRANSFER_REQUISITION_GENERATED";
    ApplicationStatusCode["TRANSFER_REQUISITION_RECEIVED"] = "TRANSFER_REQUISITION_RECEIVED";
    ApplicationStatusCode["TRANSFER_REQUISITION_GENERATED_ADDITIONAL"] = "TRANSFER_REQUISITION_GENERATED_ADDITIONAL";
    ApplicationStatusCode["TRANSFER_APPROVED"] = "TRANSFER_APPROVED";
    ApplicationStatusCode["TRANSFER_REFUSED"] = "TRANSFER_REFUSED";
})(ApplicationStatusCode || (ApplicationStatusCode = {}));
var AuthenticationErrors;
(function (AuthenticationErrors) {
    AuthenticationErrors["PIN_TOO_OLD"] = "PIN_TOO_OLD";
    AuthenticationErrors["PIN_INCORRECT"] = "PIN_INCORRECT";
    AuthenticationErrors["FILE_LOCKED"] = "FILE_LOCKED";
    AuthenticationErrors["USER_NOT_FOUND"] = "USER_NOT_FOUND";
    AuthenticationErrors["NO_CONSENT"] = "NO_CONSENT";
    AuthenticationErrors["ALREADY_UNSUBSCRIBED"] = "ALREADY_UNSUBSCRIBED";
    AuthenticationErrors["ALREADY_SUBSCRIBED"] = "ALREADY_SUBSCRIBED";
})(AuthenticationErrors || (AuthenticationErrors = {}));

export { ApplicationStatusCode, AuthenticationErrors, ConsentStatus, CorrespondenceTypes, EventTypes, FilePhase, FilePhaseState, LetterTypes, OverallDecisionTypes, RequisitionTypes, StatusStage };
