import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { AuthenticationErrors } from '@lansw-tracker/common';
import * as Sentry from '@sentry/nextjs';

import { trackEvent } from '../ga';

import theme from '../theme';
import { ActionButton } from '../Buttons/Buttons';
import { HeadingMedium, Paragraph } from '../Typography/Typography';
import { ErrorMessage } from '../Typography/ErrorMessage';

const StyledForm = styled.form`
  margin: 0 0 ${theme.spacing.m} 0;
`;

const StyledInput = styled.input`
  letter-spacing: 1em;
  margin: ${theme.spacing.xxs} 0 0 0;
  width:100%;
  max-width: 345px;
  padding: 8px 16px;
  font-size: 28px;
  line-height: 33px;
  color: ${theme.colors.black};
  background: #FFFFFF;
  border: 2px solid ${theme.colors.lighterGrey};
  box-shadow: 6px 6px 24px rgba(0, 0, 0, 0.06);
  border-radius: 3px;
  &:focus{
    outline: none;
    border: 2px solid ${theme.colors.secondaryBlue};
  }
`;
const StyledResend = styled.button`
  border: 0;
  background: none;
  opacity: ${({ disabled }): number => (disabled ? 0.3 : 1)};
  color: ${theme.colors.clickableBlue};
  text-decoration: none;
  margin: ${theme.spacingValues.xxs}px 0;
  padding: 12px 0;
  cursor: pointer;
  display: block;
  font-size: ${theme.fontSize.xsx};
  font-weight: 600;
`;

interface CodeEntryProps {
  queryID: string;
  onValidCode: () => void;
}

enum componentState {
  IDLE,
  CHECKING_CODE,
  RESENDING_CODE,
  ERROR,
}

const CodeEntry: React.FC<CodeEntryProps> = ({ queryID, onValidCode }) => {
  const [code, setCode] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [formState, setFormState] = useState <componentState>(componentState.IDLE);

  const validCode = code.trim().match(/^\d{6}$/);
  

  useEffect(() => {
    if (formState === componentState.ERROR) {
      trackEvent('PIN', 'incorrect');
    }
  }, [error]);

  useEffect(() => {
    if (formState === componentState.ERROR) {
      setFormState(componentState.IDLE);
    }
  }, [code]);

  const submitCode = async (): Promise<void> => {
    setFormState(componentState.CHECKING_CODE);
    setError(null);
    try {
      await axios
      .create({ withCredentials: true })
      .post(`${process.env.api}/auth/login`, {
        username: queryID,
        password: code,
      });
      onValidCode();
    } catch (ex: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
      switch(ex.response.data.message){
        case AuthenticationErrors.PIN_TOO_OLD:
          setError('Your code has expired. Resend code and try again.');
        break;
        case AuthenticationErrors.FILE_LOCKED:
          setError('Your file has been locked.');
          location.reload();
        break;
        case AuthenticationErrors.PIN_INCORRECT:
        case AuthenticationErrors.USER_NOT_FOUND:
          setError('That code is incorrect. Try again');
        break;
        default:
          setError('There\'s an error with the server. Please try again another time.');
          Sentry.captureException(ex);
      }
    }
    setFormState(componentState.IDLE);
  };

  const resendCode = async (): Promise<void> => {
    setFormState(componentState.RESENDING_CODE);
    setError(null);
    try {
      await axios
        .create({ withCredentials: true })
        .post(`${process.env.api}/auth/resend`, {
          urlCode: queryID,
        });
    } catch {
      setError('Couldn\'t send you a code');
    }
    setTimeout(() => {
      setFormState(componentState.IDLE);
    }, 5000);
  };

  return (
    <div data-cy="code-entry">
      <HeadingMedium $marginBottom={theme.spacingValues.xs} $marginTop={theme.spacingValues.xxxs} $fontSize={theme.fontSize.xsx}>
        We sent a private 6 digit code to your phone number.
      </HeadingMedium>
      
      <StyledForm>
        <label htmlFor="pin-entry">
          <Paragraph $light>Enter your code</Paragraph>
          <StyledInput value={code} onChange={(evt): void => setCode(evt.target.value)} id="pin-entry" data-cy="code-entry" />
        </label>
        { error && <ErrorMessage data-cy="code-error" errorText={error}/>}
        <ActionButton
          disabled={formState === componentState.CHECKING_CODE || formState === componentState.RESENDING_CODE}
          $ariaDisabled={!validCode || formState !== componentState.IDLE}
          $marginTop={theme.spacingValues.s}
          type="submit"
          onClick={(e: any): void => { // eslint-disable-line @typescript-eslint/no-explicit-any
            e.preventDefault();
            if (validCode) {
              submitCode();
            } else {
              setError('Please enter your 6 digit code');
            }
          }}
          $actionText='See application status'
        />
        <StyledResend
        disabled={formState === componentState.CHECKING_CODE || formState === componentState.RESENDING_CODE}
        onClick={async (): Promise<void> => {
          resendCode();
        }}
        >
          {formState === componentState.RESENDING_CODE ? 'Check your messages' : 'Resend Code'}
        </StyledResend>
      </StyledForm>
    </div>
  );
};

export default CodeEntry;
